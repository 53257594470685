import React, { useEffect, useMemo, useState } from "react";
import "./AddCollectionModal.scss";
import { Modal, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "../../SignupProcess/Button/Button";
import { s3Upload } from "../../../helpers/s3Upload";
import { nanoid } from "nanoid";
import { artistUpdate } from "../../../api/dmmeendpoints/artistUpdate";
import { nftCollectionsAtom } from "../../../atoms/newSeries";
import { useAtom } from "jotai";

export default function AddCollectionModal({
  isVisible,
  toggleModal,
  toEdit = null,
  deleteCollection = () => {},
}) {
  const [nftCollectionsAtomData, setNftCollectionsAtomData] =
    useAtom(nftCollectionsAtom);

  const [data, setData] = useState({});
  useEffect(() => {
    if (toEdit) {
      setData({
        name: toEdit.title,
        description: toEdit.description,
        imageUrl: toEdit.icon,
      });
    }
  }, [toEdit]);

  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div className="upload-btn">
      <div className="plus-icon-container">
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
      </div>
      <div className="upload-text"> Add an image (jpg, png) 300x300px max</div>
    </div>
  );
  const beforeUpload = async (file) => {
    setLoading(true);
    //
    await s3Upload(file, null, null, 0, [], (shortUrls) => {
      setData({ ...data, imageUrl: shortUrls[0] });
      setLoading(false);
    });
  };
  const submit = async () => {
    const nft_collections =
      nftCollectionsAtomData ||
      JSON.parse(localStorage.getItem("dbUser")).nft_collections;

    const userObject = JSON.parse(localStorage.getItem("dbUser"));
    //
    let newData;
    if (toEdit) {
      const updatedData = [...nft_collections];

      const indexToUpdate = updatedData.findIndex(
        (el) => el.collection_id === toEdit.id
      );
      //
      updatedData[indexToUpdate] = {
        collection_name: data.name,
        collection_id: toEdit?.id,
        collection_description: data.description,
        icon: data.imageUrl,
        series: toEdit?.items || [],
      };
      newData = [...updatedData];
      //
    } else {
      newData = [
        ...nft_collections,
        {
          collection_name: data.name,
          collection_id:
            data.name.replaceAll(" ", "_").toLowerCase() + "_" + nanoid(6),
          collection_description: data.description,
          icon: data.imageUrl,
          series: [],
        },
      ];
    }
    localStorage.setItem("nft_collections", JSON.stringify(newData));
    setNftCollectionsAtomData(newData);
    const newUser = { ...userObject, nft_collections: newData };
    await artistUpdate({ object: newUser, artistId: userObject.artist_id });
    localStorage.setItem("dbUser", JSON.stringify({ ...newUser }));
    setData({});
    toggleModal();
    //
  };
  //
  return (
    <Modal
      onCancel={toggleModal}
      open={isVisible}
      footer={null}
      title={toEdit ? "Update collection" : "Create a Collection"}
      className="collection-modal"
    >
      <label>Collection Image</label>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        {data.imageUrl ? (
          <img src={data.imageUrl} alt="avatar" className="collection-icon" />
        ) : (
          uploadButton
        )}
      </Upload>
      <b className="explanatory">
        This will be used for display purposes only.
      </b>
      <label>Name</label>
      <input
        placeholder="Example: Sole Hunter"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <label>Description (optional)</label>
      <textarea
        placeholder="Enter a description..."
        value={data.description}
        onChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <div className="buttons">
        <Button
          label={"CANCEL"}
          border="none"
          background="#ECECED"
          height="44px"
          action={toggleModal}
        />
        <Button
          label={toEdit ? "UPDATE" : "CREATE"}
          border="none"
          height="44px"
          action={submit}
          disabled={!(data.name && data.imageUrl)}
        />
      </div>
    </Modal>
  );
}
