import React, { useEffect, useMemo, useState } from "react";
import "./Collections.scss";
import CollectionCard from "../../../components/CollectionComponents/CollectionCard/CollectionCard";
import { useHistory } from "react-router-dom";
import CollectionId from "./CollectionId/CollectionId";
import AddCollectionModal from "../../../components/CollectionComponents/AddCollectionModal/AddCollectionModal";
import { useAtom } from "jotai";
import { nftCollectionsAtom } from "../../../atoms/newSeries";
import { artistUpdate } from "../../../api/dmmeendpoints/artistUpdate";

export default function Collections() {
  const [showEditModal, setShowEditModal] = useState(null);
  const [showCollectionModal, setShowCollectionModal] = useState();
  const [data, setData] = useState([]);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] =
    useState(false);
  const [nftCollectionsAtomData, setNftCollectionsAtomData] =
    useAtom(nftCollectionsAtom);

  const history = useHistory();

  const profilePic = JSON.parse(localStorage.getItem("dbUser"))?.bot_config
    ?.contact_info?.profile_picture;

  useEffect(() => {
    //
    //   JSON.parse(localStorage.getItem("dbUser"))?.nft_collections,
    //   "ASHDGASDAS"
    // );
    setData(
      (
        nftCollectionsAtomData ||
        JSON.parse(localStorage.getItem("dbUser"))?.nft_collections
      )?.map((el) => ({
        id: el?.collection_id,
        title: el?.collection_name.includes("undefined")
          ? el?.collection_name.replace(/^undefined\s*/, "")
          : el?.collection_name,
        icon: el?.icon || profilePic || "https://go.iindy.co/LGi2J",
        status: el?.status || "Live",
        items: el?.series,
        description: el?.collection_description,
      }))
    );
  }, [nftCollectionsAtomData, localStorage.getItem("dbUser")]);

  //
  //   nftCollectionsAtomData,
  //   "wtf",
  //   data,
  //   JSON.parse(localStorage.getItem("dbUser"))?.nft_collections
  // );

  const deleteCollection = async (id, newData = null) => {
    //
    const userObject = JSON.parse(localStorage.getItem("dbUser"));
    const newCollections = [
      ...(newData ||
        nftCollectionsAtomData ||
        JSON.parse(localStorage.getItem("dbUser"))?.nft_collections),
    ];
    newCollections.splice(
      newCollections.findIndex((old) => old.collection_id === id),
      1
    );

    setNftCollectionsAtomData(newCollections);
    const newUser = { ...userObject, nft_collections: newCollections };
    //
    await artistUpdate({ object: newUser, artistId: userObject.artist_id });
    localStorage.setItem("dbUser", JSON.stringify({ ...newUser }));
    setShowDeleteCollectionModal(false);
    setShowEditModal(null);
  };
  useEffect(() => {
    if (showEditModal !== null) {
      setShowCollectionModal(true);
    }
  }, [showEditModal]);

  if (history.location.pathname === "/dashboard/collections") {
    return (
      <div className="collections-wrapper">
        <h1>My Collections</h1>
        <div className="collections-card-container">
          <CollectionCard
            status={"create"}
            action={() => setShowCollectionModal(true)}
          />
          {data?.map((col) => (
            <CollectionCard
              key={col.id}
              {...col}
              deleteCollection={deleteCollection}
              showDeleteCollectionModal={showDeleteCollectionModal}
              setShowDeleteCollectionModal={setShowDeleteCollectionModal}
              setShowEditModal={setShowEditModal}
            />
          ))}
          <AddCollectionModal
            isVisible={showCollectionModal}
            toggleModal={() => setShowCollectionModal(!showCollectionModal)}
            toEdit={showEditModal}
            deleteCollection={deleteCollection}
          />
        </div>
      </div>
    );
  }
  return <CollectionId />;
}
