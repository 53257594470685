import { Dropdown, Input, Modal } from "antd";
import _, { flow } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { MdFilterList } from "react-icons/md";
import { getWhatsappFlowMessage } from "../../../../api/dmmeendpoints/getWhatsappFlows";
import SwitchComponent from "../../../../components/Switch/SwitchComponent";
import ChatView from "./ChatView/ChatView";
import "./MessageReplies.scss";
import ReplyListItem from "./ReplyListItem/ReplyListItem";
import { LuUsers } from "react-icons/lu";
import { TbUserX } from "react-icons/tb";
import chatStarted from "../../../../assets/Dashboard/chat-started.svg";
import chatCompleted from "../../../../assets/Dashboard/chat-completed.svg";
import { downloadConversationAsCSV } from "../../../../api/dmmeendpoints/downloadConversationAsCSV";
import saveAs from "file-saver";

export default function MessageReplies({
  open,
  setSeeReplies,
  isFlow = false,
  data = null,
}) {
  const {
    avatar,
    sentTo = "all",
    title,
    message,
    images,
    raffleIds,
    sent,
    eyes,
    clicks,
    replies,
    date,
    id,
    metadata,
  } = isFlow ? {} : open;

  const [activeChat, setActiveChat] = useState(0);
  const [smartView, setSmartView] = useState(true);
  const [searchVal, setSearchVal] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [activeFilter, setActiveFilter] = useState(true);

  const formattedReplies = useMemo(
    () => _.groupBy(metadata?.fan_replies, "from"),
    [metadata?.fan_replies]
  );
  useEffect(() => {
    setActiveChat(Object.keys(formattedReplies)[0]);
  }, [formattedReplies]);

  const searchNumbers = (e) => {
    setSearchVal(e.target.value);
  };

  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  useEffect(() => {
    const loadContacts = async () => {
      const response = await getWhatsappFlowMessage(open);
      setContacts(response.msg_status_query_result || []);
      setActiveChat(contacts[0]?.contact_phone_number);
    };

    if (isFlow && open) {
      loadContacts();
    }
  }, [isFlow, open]);

  const dropdownItems = isFlow && [
    {
      key: "1",
      label: (
        <div onClick={() => setActiveFilter("everyone")}>
          <DropdownItem
            Icon={LuUsers}
            label={"Everyone"}
            number={contacts.length || 0}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => setActiveFilter("notstarted")}>
          <DropdownItem
            Icon={TbUserX}
            label={"Not Started only"}
            number={contacts.filter((c) => c.track_id === null).length}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={() => setActiveFilter("inprogress")}>
          <DropdownItem
            image={chatStarted}
            label={"In Progress only"}
            number={
              contacts.filter(
                (c) => c.track_id === "INPROGRESS" || c.track_id === "STARTED"
              ).length
            }
          />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div onClick={() => setActiveFilter("completed")}>
          <DropdownItem
            image={chatCompleted}
            label={"Completed only"}
            number={contacts.filter((c) => c.track_id === "COMPLETED").length}
          />
        </div>
      ),
    },
  ];

  const downloadCSV = async () => {
    const csvData = await downloadConversationAsCSV(data.flowid);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    if (window.showSaveFilePicker) {
      try {
        const handle = await window.showSaveFilePicker({
          startIn: "downloads",
          suggestedName: `${
            isFlow ? dbUser?.flowNames?.[open]?.title || open : title
          }.csv`,
        });
        if (handle) {
          const writable = await handle.createWritable();
          if (writable) {
            await writable.write(blob);
            writable.close();
          }
        }
      } catch (error) {}
    } else {
      saveAs(
        blob,
        `${isFlow ? dbUser?.flowNames?.[open]?.title || open : title}.csv`
      );
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setSeeReplies(false)}
      className="replies-modal"
      footer={null}
      closeIcon={
        <div className="replies-modal-close">
          <BiX size={24} color="white" />
        </div>
      }
    >
      <div className="replies-modal-body">
        <div className="replies-sidebar">
          <div className="replies-top">
            <div className="is-flow-header">
              <h3>
                {isFlow ? dbUser?.flowNames?.[open]?.title || open : title}
              </h3>
              {isFlow && (
                <div className="is-flow-actions">
                  <FiDownload size={24} onClick={downloadCSV} />
                  <Dropdown
                    menu={{
                      items: dropdownItems,
                    }}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <MdFilterList size={24} />
                  </Dropdown>
                </div>
              )}
            </div>

            {!isFlow ? (
              <b>You have {metadata?.fan_replies?.length} replies</b>
            ) : (
              <div className="smart-view-switch">
                {/* <SwitchComponent
                  data={smartView}
                  switchOnChange={(value) => setSmartView(value)}
                  labelName={`Smart View ${smartView ? "On" : "Off"}`}
                /> */}
              </div>
            )}
            <div className="replies-search">
              <Input
                prefix={<BiSearch />}
                placeholder="Search"
                className="replies-search-input"
                onChange={searchNumbers}
              />
            </div>
          </div>
          <div className="replies-number-list">
            {isFlow
              ? contacts
                  .filter((contact) => {
                    if (activeFilter === "inprogress") {
                      return (
                        contact.track_id === "INPROGRESS" ||
                        contact.track_id === "STARTED"
                      );
                    } else if (activeFilter === "notstarted") {
                      return contact.track_id === null;
                    } else if (activeFilter === "completed") {
                      return contact.track_id === "COMPLETED";
                    }
                    return true;
                  })
                  .map(
                    (
                      {
                        last_msg_text,
                        contact_phone_number,
                        message_count,
                        contact_display_name,
                        msg_received_time,
                      },
                      i
                    ) =>
                      (!searchVal ||
                        contact_phone_number.includes(searchVal) ||
                        contact_display_name
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())) && (
                        <ReplyListItem
                          key={contact_phone_number + i}
                          number={contact_phone_number}
                          displayName={contact_display_name}
                          message={last_msg_text}
                          isActive={activeChat === contact_phone_number}
                          noOfReplies={message_count}
                          setActiveChat={setActiveChat}
                          date={msg_received_time}
                        />
                      )
                  )
              : Object.entries(formattedReplies)?.map(
                  ([number, data], i) =>
                    (!searchVal || number.includes(searchVal)) && (
                      <ReplyListItem
                        key={i}
                        number={number}
                        message={data[data.length - 1]?.data?.Body}
                        isActive={activeChat === number}
                        noOfReplies={data.length}
                        setActiveChat={setActiveChat}
                      />
                    )
                )}
          </div>
        </div>
        <div className="chat-view">
          <ChatView
            messages={formattedReplies[activeChat]}
            artistBroadcast={message}
            images={images}
            raffleIds={raffleIds}
            broadcastDate={date}
            id={id || open}
            phoneNumber={activeChat}
            setActiveChat={setActiveChat}
            formattedReplies={formattedReplies}
            isFlow={isFlow}
            contact={
              contacts[
                contacts.findIndex(
                  (contact) => contact.contact_phone_number === activeChat
                )
              ]
            }
            contacts={isFlow ? contacts : []}
          />
        </div>
      </div>
    </Modal>
  );
}

const DropdownItem = ({ Icon, label, number, image = null }) => {
  return (
    <div className="message-replies-dropdown-item">
      <div>
        {image ? (
          <img src={image} width={18} height={18} alt={label} />
        ) : (
          <Icon size={18} />
        )}
        <span>{label}</span>
      </div>
      <div className="message-replies-dropdown-badge">{number}</div>
    </div>
  );
};
